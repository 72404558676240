import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {
  auth,
  firestore,
  SignInWithApple,
  SignInWithFacebook,
  SignInWithGoogle,
  users,
} from "../../utils/firebase.utils";
import {useTheme} from "@mui/system";
import Form from "../../components/forms/form-wrapper";
import AuthContainer from "../../components/data-display/AuthContainer";
import InlineLink from "../../components/data-display/inline-link";
import GoogleIcon from "../../images/icons/google.png";
import FacebookIcon from "../../images/icons/facebook.png";
import AppleIcon from "../../images/icons/apple.png";
import SecondaryButton from "../../components/buttons/secondary-button";
import FormInput from "../../components/forms/form-input";
import Text from "../../components/data-display/text";
import FormErrorText from "../../components/data-display/form-error-text";
import Button from "../../components/buttons/primary-button";
import PlayStore from "../../images/google_playstore.svg";
import AppStore from "../../images/apple-appstore.svg";
import {useMediaQuery} from "@mui/material";
import SEO from "../../components/seo/seo";
import {Link} from "gatsby";
import {NoAuthRoute} from "../../components/app-routes/app-routes";
import {useLocation, Router} from "@reach/router";
import {environment} from "../../environments/quickTaskEnvironemt";
import {
  title,
  isQuickTaskProject,
  project,
  descriptionInfo,
  appStoreLink,
  GooglePlayLink,
  yardWork,
} from "../../utils/constant.urls";
const QuikTaskMainColor = environment.mainColor;

const LoginPage = ({history}) => {
  const theme = useTheme();
  const styles = {
    background: {
      display: "flex",
      background: theme.palette.background.default,
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100vh",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        justifyContent: "flex-start",
      },
    },
  };
  const palette_Color = isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main;
  const xsDown = useMediaQuery(theme.breakpoints.down("sm"));
  const [error, setError] = useState("");
  const [loading, setLoading] = useState<string>("");

  useEffect(() => {
    auth
      .getRedirectResult()
      .then()
      .catch(function (error) {
        setError(error.message);
      });
  });

  // Handle Login
  const login = ({email, password}: Record<string, string | boolean>) => {
    return new Promise((resolve, reject) => {
      if (typeof email === "string" && typeof password === "string") {
        setLoading("login");
       firestore
          .collection("users")
          .where("email", "==", email)
          .where("project", "==", project)
          .get()
          .then((querySnapshot) => {
            let projectMatch = false;
            querySnapshot.forEach((doc) => {
              const userData = doc.data();
              if (userData.project === project) {
                projectMatch = true;
              }
            });
            //if (projectMatch) {
            auth
              .signInWithEmailAndPassword(email, password)
              .then(() => {
                setLoading(""); // Clear loading state after successful sign-in
                resolve(""); // Resolve the promise to indicate successful login
              })
              .catch((err) => {
                setLoading(""); // Clear loading state if login fails
                switch (err.code) {
                  case "auth/user-not-found":
                    reject({email: "Couldn't find your account."});
                    break;
                  case "auth/wrong-password":
                    reject({password: "That's an incorrect password. Try again."});
                    break;
                  case "auth/invalid-email":
                    reject({email: "Please enter a valid Email Address."});
                    break;
                  default:
                    reject("Something went wrong on our side.");
                }
              });
            //} else {
            //  setLoading(""); // Clear loading state since no login attempt was made
            //  reject("User does not have access to this project...");
            //  console.log(
            //    "userData.project, === project",
            //    //userData.project,
            //    project
            //  );
            //}
          })
          .catch((error) => {
            setLoading(""); // Clear loading state if Firestore query fails
            reject("Error fetching user data.");
          });
      }
    });
  };

  // Handle Google Sign In
  const loginWithGoogle = () => {
    <Link to="/login?auth=google" />;
    try {
      setLoading("googleLogin");
      SignInWithGoogle()
        .then((result) => {
          setLoading("");
        })
        .catch((err) => {
          let errorMessage = err.message;
          setError(errorMessage);
          setLoading("");
        });
    } catch (e) {
      let errorMessage = e.message;
      setError(errorMessage);
      setLoading("");
    }
  };

  // Handle Apple Sign In
  const loginWithApple = () => {
    <Link to="/login?auth=apple" />;
    try {
      setLoading("appleLogin");
      SignInWithApple()
        .then(() => {
          setLoading("");
        })
        .catch((err) => {
          let errorMessage = err.message;
          setError(errorMessage);
          setLoading("");
        });
    } catch (e) {
      let errorMessage = e.message;
      setError(errorMessage);
      setLoading("");
    }
  };

  // Handle Facebook Login
  const loginWithFacebook = () => {
    <Link to="/login?auth=facebook" />;
    try {
      setLoading("facebookLogin");
      SignInWithFacebook()
        .then(() => {
          setLoading("");
        })
        .catch((err) => {
          let errorMessage = err.message;
          setError(errorMessage);
          setLoading("");
        });
    } catch (e) {
      let errorMessage = e.message;
      setError(errorMessage);
      setLoading("");
    }
  };

  return (
    <>
      <SEO
        title={`${title} - ${descriptionInfo}`}
        pathname={"/login"}
        description={`Any task you need done? ${title} easily connects you with trusted & experienced Taskers ready to help with home repairs, heavy lifting, yard ${yardWork}, & more.`}
      />
      <div
        style={{
          ...(styles.background as React.CSSProperties),
        }}
      >
        <div style={{marginTop: xsDown ? "45px" : "170px"}} />
        <div style={{display: "none"}}>{error}</div>
        <Grid justifyContent={"center"} component={"div"} container>
          <Grid
            component={"div"}
            xl={5}
            lg={5}
            md={6}
            sm={6}
            xs={12}
            item
            style={{display: "flex", justifyContent: "center"}}
          >
            <AuthContainer padding>
              <Form
                disableLoading
                onSubmit={login}
                style={{display: "flex", justifyContent: "center"}}
                initialValues={{
                  email: "",
                  password: "",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    justifyContent: "space-between",
                    marginBottom: "16px",
                  }}
                >
                  <Text variant={"h5"}>Sign In</Text>
                  <Text variant={"body2"}>
                    <span style={{opacity: "0.6", marginRight: "8px"}}>New User?</span>
                    <Text variant={"body2"} bold component={"span"}>
                      <InlineLink
                        to={"/register"}
                        style={{color: palette_Color}}
                        variant={"primary"}
                      >
                        Sign Up
                      </InlineLink>
                    </Text>
                  </Text>
                </div>
                <FormInput placeholder={"Email Address"} name={"email"} type={"email"} />
                <FormInput placeholder={"Password"} name={"password"} type={"password"} />
                <Text variant={"caption"} medium component={"span"} style={{marginLeft: "auto"}}>
                  <InlineLink to={`/forget-password`} style={{color: "grey"}} variant={"secondary"}>
                    Forgotten Password?
                  </InlineLink>
                </Text>
                <FormErrorText />
                <Button
                  type="submit"
                  isLoading={loading === "login"}
                  key={"button5"}
                  style={{marginTop: "18px", backgroundColor: palette_Color}}
                >
                  {" "}
                  Sign In{" "}
                </Button>
                <div style={{display: "flex", margin: "18px 5%", alignItems: "center"}}>
                  <div style={{flex: "1", height: "2px", background: "grey", opacity: "0.1"}} />
                  <Text
                    variant={"body2"}
                    medium
                    component={"span"}
                    style={{color: "grey", margin: "0 12px"}}
                  >
                    or
                  </Text>
                  <div style={{flex: "1", height: "2px", background: "grey", opacity: "0.1"}} />
                </div>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                  <SecondaryButton
                    type="button"
                    key={"apple_login"}
                    isLoading={loading === "appleLogin"}
                    background={"rgb(10, 10, 10)"}
                    onClick={loginWithApple}
                    textColor={"white"}
                    icon={AppleIcon}
                  >
                    Continue with Apple
                  </SecondaryButton>
                  <div style={{height: "10px"}} />
                  <SecondaryButton
                    type="button"
                    key={"facebook_login"}
                    isLoading={loading === "facebookLogin"}
                    background={"rgb(66, 103, 178)"}
                    onClick={loginWithFacebook}
                    textColor={"white"}
                    icon={FacebookIcon}
                  >
                    Continue with Facebook
                  </SecondaryButton>
                  <div style={{height: "10px"}} />
                  <SecondaryButton
                    dark
                    type="button"
                    key={"google_login"}
                    isLoading={loading === "googleLogin"}
                    background={"white"}
                    onClick={loginWithGoogle}
                    textColor={"rgb(40,40,40)"}
                    icon={GoogleIcon}
                  >
                    Continue with Google
                  </SecondaryButton>
                </div>
                <div style={{marginTop: "20px"}} />
                <Text
                  variant={"caption"}
                  medium
                  component={"span"}
                  style={{color: "grey", width: "80%", margin: "0 auto"}}
                >
                  By clicking Sign In, Continue with Apple, Continue with Facebook or Continue with
                  Google, you agree to our{" "}
                  <InlineLink to={"/terms"} style={{color: palette_Color}} variant={"primary"}>
                    Terms & Conditions
                  </InlineLink>{" "}
                  and{" "}
                  <InlineLink
                    to={"/privacy-policy"}
                    style={{color: palette_Color}}
                    variant={"primary"}
                  >
                    Privacy Policy
                  </InlineLink>
                </Text>
              </Form>
              {xsDown && (
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "22px",
                  }}
                >
                  <a href={GooglePlayLink}>
                    <img loading="lazy" src={PlayStore} alt={`Download ${title} App`} />
                  </a>
                  <div style={{width: "18px"}} />
                  <a href={appStoreLink}>
                    <img loading="lazy" src={AppStore} alt={`Download ${title} App`} />
                  </a>
                </div>
              )}
            </AuthContainer>
          </Grid>
        </Grid>
        <div style={{marginTop: xsDown ? "45px" : "100px"}} />
      </div>
    </>
  );
};
const App = () => {
  const location = useLocation();
  return (
    <div className="content">
      <Router location={location}>
        <NoAuthRoute path={"/login"} component={LoginPage} />
      </Router>
    </div>
  );
};

export default App;
